import { createRoot, Root } from 'react-dom/client';
import AddToCart, { type AddToCartProps as AddToCartProps, stylesList } from './AddTocart';
import type { PropsWithChildren } from 'react';

class StandaloneComponentAddToCart extends HTMLElement {
  mountPoint!: HTMLSpanElement;
  private addTocartProps: Partial<AddToCartProps> = { children: null };
  private root!: Root;

  connectedCallback() {
    const mountPoint = document.createElement('div');
    const shadowRoot = this.attachShadow({ mode: 'open' });

    mountPoint.classList.add('w-full');

    for (const styleInfo of stylesList) {
      const styleElement = document.createElement('style');
      styleElement.textContent =
        styleInfo.style + ' :host { width: 100% } stoe-addtocart { width: 100% }';
      shadowRoot.appendChild(styleElement);
    }
    shadowRoot.appendChild(mountPoint);

    // Populate Props from attributes
    const attributeNames = this.getAttributeNames();
    attributeNames.forEach((attrName) => {
      const value = this.getAttribute(attrName);
      if (value !== null) {
        //@ts-expect-error this line throws an error if no props are in the component, but the code will still compile, since attributeNames is empty and will not map
        this.addTocartProps[attrName as keyof PropsWithChildren<AddToCartProps>] = value;
      }
    });

    this.root = createRoot(mountPoint);
    this.renderComponent();
  }

  static get observedAttributes() {
    return ['configuration', 'token', 'store', 'label', 'disabled', 'lang', 'classes']; // Add other attributes here if needed
  }

  attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
    if (oldValue !== newValue) {
      //@ts-expect-error this line throws an error if no props are in the component, but the code will still compile, since attributeNames is empty and will not map
      this.addTocartProps[name as keyof PropsWithChildren<AddToCartProps>] = newValue;
      this.renderComponent();
    }
  }

  private renderComponent() {
    if (this.root) {
      //eslint-disable-next-line
      //@ts-ignore
      this.root.render(<AddToCart {...this.addTocartProps} />);
    }
  }
}

export default StandaloneComponentAddToCart;
// the name of the custom Element can be edited here:
window.customElements.get('stoe-addtocart') ||
  window.customElements.define('stoe-addtocart', StandaloneComponentAddToCart);
