// import * as Result from 'postcss/lib/result';

import compStyle from './addTocart.css?inline';

import type { PropsWithChildren } from 'react';
import { dispatchErrorEvent, dispatchSuccessEvent } from './addTocart.events';
import { cn } from './helper';
// @root points to root of parent dir. Use it like this:
// import globalStyle from '@root/src/app/globals.css?inline'

// styles need to be exported to entrypoint like this:
const stylesList = [
  { id: 'CompStyles', style: compStyle },
  // { id: 'GlobalStyles', style: globalStyle },
];
const sapCommerTestUrl = import.meta.env.PROD
  ? 'https://api.cu91cv9jmr-stoberant1-p1-public.model-t.cc.commerce.ondemand.com/occ/v2'
  : 'https://api.cu91cv9jmr-stoberant1-d1-public.model-t.cc.commerce.ondemand.com/occ/v2';

// this props also get exported to entrypoint
interface AddToCartPropsBase {
  configuration?: string;
  token?: string;
  store?: 'sat' | 'sdi';
  label?: string;
  disabled?: 'true' | 'false';
  lang?: string;
  classes?: string;
}

type AddToCartProps = PropsWithChildren<AddToCartPropsBase>;

const BUTTON_CLASS = {
  default:
    'p-2 font-bold bg-white border-2 border-stoe-gray-dark text-stoe-gray-dark hover:bg-stoe-gray-dark hover:text-white',
  primary: 'p-2 font-bold bg-stoe-blue  text-white hover:bg-stoe-gray-dark hover:text-white',
  disabled:
    'p-2 font-bold bg-white border-2 pointer-events-none cursor-not-allowed border-stoe-gray-light bg-stoe-gray-light text-white',
};

const addToCartPost = async (
  token: string,
  locale: string,
  store: AddToCartProps['store'],
  configuration: string,
): Promise<boolean> => {
  const baseSite = `${store?.toLocaleLowerCase()}Site`;

  try {
    const response = await fetch(
      `${sapCommerTestUrl}/${baseSite}/users/current/carts/current/entries?fields=FULL&lang=${locale.substring(
        0,
        2,
      )}`,
      {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        body: JSON.stringify({
          quantity: 1,
          stoeProductConfiguration: configuration,
        }),
      },
    );
    const result = await response.json();
    if (result.errors) throw result.errors;
    return result;
  } catch (error) {
    console.log('addToCart error', error);
    throw error;
  }
};

const createNewCart = async (
  token: string,
  locale: string,
  store: AddToCartProps['store'],
): Promise<boolean> => {
  const baseSite = `${store?.toLocaleLowerCase()}Site`;

  try {
    const respone = await fetch(
      `${sapCommerTestUrl}/${baseSite}/users/current/carts/?fields=FULL&lang=${locale.substring(
        0,
        2,
      )}`,
      {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    const result = await respone.json();
    if (result.errors) throw result.errors;
    return true;
  } catch (error) {
    console.log('createNewCart error', error);
    throw error;
  }
};

const getCart = async (
  token: string,
  locale: string,
  store: AddToCartProps['store'],
): Promise<boolean> => {
  const baseSite = `${store?.toLocaleLowerCase()}Site`;
  const cartUrl = `${sapCommerTestUrl}/${baseSite}/users/current/carts/current?lang=${locale.substring(
    0,
    2,
  )}`;

  try {
    const response = await fetch(cartUrl, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
    const result = await response.json();

    if (result.errors) {
      const cartNotFound = result.errors.some(
        (error: Error & { reason: string }) => error.reason === 'notFound',
      );
      if (cartNotFound) {
        return false;
      }
      throw result.errors;
    }
    return true;
  } catch (error) {
    throw error;
  }
};

const postToCart = async (
  accessToken: string,
  locale: string,
  store: AddToCartProps['store'],
  configuration: string,
) => {
  let hasActiveCart = false;

  try {
    if (!accessToken) throw Error('not authorized');

    const hasCart = await getCart(accessToken, locale, store);
    if (hasCart) hasActiveCart = true;
    if (!hasActiveCart) {
      const successfulCartCreation = await createNewCart(accessToken, locale, store);
      if (successfulCartCreation) hasActiveCart = true;
    }

    if (hasActiveCart) {
      const cartResponse = await addToCartPost(accessToken, locale, store, configuration);
      if (cartResponse) {
        dispatchSuccessEvent(cartResponse);
      }
    }
  } catch (error) {
    console.log('error', error);
    dispatchErrorEvent(error as Error);
  }
};

const AddToCart: React.FC<AddToCartProps> = ({
  configuration,
  token,
  store = 'sat',
  label = 'Add to cart',
  disabled = 'false',
  lang = 'de-DE',
  classes,
}: PropsWithChildren<AddToCartProps>) => {
  const handleClick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (token && lang && store && configuration) {
      postToCart(token, lang, store, configuration);
    }
  };
  return (
    <>
      <button
        className={cn(BUTTON_CLASS.primary, classes, 'w-full font-cb-bold')}
        data-testid="stoe-addTocart"
        disabled={disabled === 'true' || !token}
        onClick={handleClick}
      >
        {label}
      </button>
    </>
  );
};

export { stylesList };
export type { AddToCartProps };
export default AddToCart;
