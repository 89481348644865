// CONSTANTS
const ADDTOCART_EVENT = {
    SUCCESS: 'stoeAddToCartSuccess',
    ERROR: 'stoeaddToCartError',
  };
  
  const dispatchSuccessEvent = (response: unknown) => {
    const stoeSuccessEvent = new CustomEvent(ADDTOCART_EVENT.SUCCESS, { detail: response });
  
    window.dispatchEvent(stoeSuccessEvent);
  };
  
  const dispatchErrorEvent = (errorLog: Error) => {
    const stoeErrorEvent = new CustomEvent(ADDTOCART_EVENT.ERROR, { detail: errorLog });
  
    window.dispatchEvent(stoeErrorEvent);
  };

  export {ADDTOCART_EVENT, dispatchErrorEvent,dispatchSuccessEvent}